var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app-bar',{staticClass:"py-1 px-6 navbar",attrs:{"fixed":"","app":"","elevation":"0","color":"white"}},[(_vm.$route.name === 'DeviceInner')?_c('v-btn',{staticClass:"px-0",attrs:{"text":"","link":"","color":"muted","ripple":false,"plain":""},on:{"click":function($event){return _vm.$router.push({
        name: 'DeviceList',
        query: {
          applicationId: _vm.$route.query.applicationId,
          productKey: _vm.$route.query.productKey,
        },
      })}}},[_c('v-icon',[_vm._v("mdi-menu-left")]),_vm._v(" Back ")],1):_vm._e(),_c('v-toolbar-title',{staticClass:"title d-flex font-weight-medium"},[(_vm.$route.name === 'DeviceList')?_c('span',[_vm._v("Device list")]):_vm._e(),(_vm.$route.name === 'CompanyList')?_c('span',[_vm._v("Device summary")]):_vm._e()]),_c('v-spacer'),(_vm.$route.name === 'DeviceInner' && !_vm.sidebarLoading)?_c('div',{staticClass:"d-flex justify-center align-center"},[_c('v-btn',{staticClass:"px-0 me-3 w-auto",attrs:{"color":"muted","outlined":"","small":""},on:{"click":function($event){return _vm.getPrevItem(_vm.itemData)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-arrow-left")])],1),_c('h4',{staticClass:"title font-weight-bold"},[_vm._v("Device Data")]),_c('v-btn',{staticClass:"px-0 ms-3 w-auto",attrs:{"disabled":_vm.nextDisabled,"color":"muted","outlined":"","small":""},on:{"click":function($event){return _vm.getNextItem(_vm.itemData)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-arrow-right")])],1)],1):_vm._e(),_c('v-spacer'),_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{attrs:{"hide-details":"","item-text":"currentTime","item-value":"value","items":_vm.timezones,"label":"Timezone"},on:{"input":_vm.changeTimezone},model:{value:(_vm.timezone),callback:function ($$v) {_vm.timezone=$$v},expression:"timezone"}})],1),_c('v-menu',{attrs:{"offset-y":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('h6',_vm._g(_vm._b({staticClass:"subtitle-1 font-weight-bold ms-2"},'h6',attrs,false),on),[_vm._v(" "+_vm._s(_vm.userMe.userName)+" ")]),_c('v-btn',_vm._g(_vm._b({staticClass:"me-3",attrs:{"outlined":"","icon":"","color":"light"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"muted","small":""}},[_vm._v("mdi-account-outline")])],1)]}}])},[_c('v-list',[_c('v-list-item',{attrs:{"ripple":false},on:{"click":_vm.logoutAction}},[_c('v-list-item-title',[_vm._v(" Log Out ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }